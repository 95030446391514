import React from "react";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import UpdateAjuste from "./UpdateAjuste";
import moment from "moment";

import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  table: {
    minWidth: 650
  },
  button: {
    "& > *": {
      margin: theme.spacing(2)
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200
  },
  filtro: {
    textAlign: "right"
  },
  divTextField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  notData: {
    margin: theme.spacing(1),
    textAlign: "center"
  }
}));

export default function TabelaAjuste(props){
  const classes = useStyles();
  const dataPontoAjustes = props.dataPontoAjustes;
  const onChangeItem = props.onChangeItem;

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="ajustes-pontos">
        <TableHead>
          <TableRow>
            <TableCell>Funcionario</TableCell>
            <TableCell align="left">Data</TableCell>
            <TableCell align="left">Hora</TableCell>
            <TableCell align="left">Motivo</TableCell>
            <TableCell align="left">Situação</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataPontoAjustes.map((ajuste, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {ajuste.dscUser.nome}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(ajuste.data).format("DD/MM/Y")}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(ajuste.data).format("HH:mm")}
              </TableCell>
              <TableCell component="th" scope="row">
                {ajuste.dscMotivo.descricao}
              </TableCell>
              <TableCell component="th" scope="row">
                <Chip
                  size="small"
                  label={ajuste.dscStatus}
                  clickable
                  color={
                    ajuste.dscStatus === "PENDENTE" ? "secondary" : "primary"
                  }
                  deleteIcon={<DoneIcon />}
                />
              </TableCell>
              <TableCell align="left">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  <UpdateAjuste
                    id={ajuste.id}
                    status={ajuste.dscStatus === "APROVADO" ? true : false}
                    onChangeItem={onChangeItem}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
