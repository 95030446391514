import React, {useState, useEffect} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {lighten, makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";

import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";

import api from "services/api";
import {getToken} from "services/auth";
import Swal from "sweetalert2";

import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";

import TabelaAjuste from "./TabelaAjuste";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginBottom: "10px",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  button: {
    "& > *": {
      margin: theme.spacing(2)
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200
  },
  filtro: {
    textAlign: "right"
  },
  divTextField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  }
}));

export default function Filtro(){
  const classes = useStyles();
  const headers = {Authorization: "Bearer " + getToken()};
  const [ dataPontoAjustes, setDataPontoAjustes ] = useState([]);

  const [ isLoading, setIsLoading ] = useState(true);

  const [ dataIni, setDataIni ] = useState(
    moment(new Date()).add(-7, "days").format("YYYY-MM-DD")
  );
  const [ dataFim, setDataFim ] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  //Busca os usuários
  const [ user, setUser ] = useState("");
  const [ users, setUsers ] = useState([]);
  const handleChangeSelect = event => {
    setUser(event.target.value);
  };

  useEffect(() => {
    try {
      api.get("ponto/usuarios", {headers}).then(res => setUsers(res.data.data));
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "não pegou!"
      });
    }
  }, []);

  //Busca as situações
  const [ sit, setSit ] = useState("");
  const [ status, setStatus ] = useState([]);
  const handleChangeSelectStatus = event => {
    setSit(event.target.value);
  };

  useEffect(() => {
    try {
      api.get("ponto/status", {headers}).then(res => setStatus(res.data.data));
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api!"
      });
    }
  }, []);

  function handleFiltro(){
    try {
      setIsLoading(true);
      api
        .post(
          `ponto/ajustes/history`,
          {dataIni: dataIni, dataFim: dataFim, status: sit, user: user},
          {headers}
        )
        .then(response => {
          setIsLoading(false);
          setDataPontoAjustes(response.data.data);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos consultar os ajuste de ponto"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }

    setIsLoading(true);
  }

  useEffect(() => {
    getAllAjustes();
  }, []);

  const getAllAjustes = async () => {
    setIsLoading(true);
    try {
      await api
        .get(`ponto/ajustes`, {headers})
        .then(response => {
          setDataPontoAjustes(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos consultar os ajuste de ponto"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
  };

  function onChangeItem(item){
    handleFiltro();
  }

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Box className={classes.button}>
            <div className={classes.divTextField}>
              <TextField
                id="dateIni"
                label="Data Inicio"
                type="date"
                defaultValue={dataIni}
                className={classes.textField}
                onChange={e => setDataIni(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="dateFim"
                label="Data Final"
                type="date"
                defaultValue={dataFim}
                className={classes.textField}
                onChange={e => setDataFim(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="usuario"
                select
                label="Usuário"
                value={user}
                onChange={handleChangeSelect}
                helperText="Por favor selecione o usuário"
              >
                {users.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nome}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="situacao"
                select
                label="Situação"
                value={sit}
                onChange={handleChangeSelectStatus}
                helperText="Por favor selecione uma situação"
              >
                {status.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={classes.filtro}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                endIcon={<SendIcon />}
                onClick={handleFiltro}
              >
                Filtrar
              </Button>
            </div>
          </Box>
        </CardContent>
      </Card>
      {isLoading != true ? (
        <TabelaAjuste
          dataPontoAjustes={dataPontoAjustes}
          onChangeItem={onChangeItem}
        />
      ) : (
        <PageLoader />
      )}
    </div>
  );
}
