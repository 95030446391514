import React, {useState, useEffect} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import {lighten, makeStyles} from "@material-ui/core/styles";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";

import CreateCentroCustos from "./CreateCentroCustos";
import TableCentroCustos from "./TableCentroCustos";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import {getToken} from "../../../services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  }
}));

const breadcrumbs = [
  {
    label: "Centro de Custos",
    link: "/",
    isActive: true
  }
];

export default function SimpleCard(){
  const classes = useStyles();

  const [ isLoading, setIsLoading ] = useState(true);

  const headers = {Authorization: "Bearer " + getToken()};
  const [ centroCusto, setCentroCusto ] = useState([]);

  useEffect(() => {
    getAllCentroCustos();
  }, []);

  const getAllCentroCustos = async () => {
    setIsLoading(true);
    try {
      api
        .get("ponto/centro-custos", {headers})
        .then(res => setCentroCusto(res.data.data))
        .catch(err => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não conseguimos consultar os centro de custos"
          });
        });
      setIsLoading(false);
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
  };

  function onChangeItem(item){
    console.log(item);
    getAllCentroCustos();
  }

  return (
    <PageContainer heading="Centro de Custos" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{marginBottom: 10}}>Gerenciar Centro de Custos</div>
          <Divider />
          <div style={{marginTop: 24}}>
            <Card className={classes.root}>
              <CardContent>
                <CreateCentroCustos onChangeItem={onChangeItem} />
                {isLoading != true ? (
                  <TableCentroCustos
                    centroCusto={centroCusto}
                    onChangeItem={onChangeItem}
                  />
                ) : (
                  <PageLoader />
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
