import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import {lighten, makeStyles} from "@material-ui/core/styles";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";

import CreateEscala from "./CreateEscala";
import TabelaEscalas from "./TabelaEscalas";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import {getToken} from "../../../services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  }
}));

const breadcrumbs = [ {label: "Escalas", link: "/", isActive: true} ];

export default function SimpleCard(){
  const classes = useStyles();
  const headers = {Authorization: "Bearer " + getToken()};
  const [ isLoading, setIsLoading ] = useState(true);

  const [ dataDias, setDataDias ] = useState([]);
  const [ dataEscalas, setDataEscalas ] = useState([]);

  useEffect(() => {
    getDaysOfTheWeek();
    getAllEscalas();
  }, []);
  const getDaysOfTheWeek = async () => {
    try {
      api
        .get("ponto/dias")
        .then(response => {
          setDataDias(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não conseguimos consultar os dias "
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);
  };

  const getAllEscalas = async () => {
    try {
      api
        .get("ponto/escalas", {headers})
        .then(response => {
          setDataEscalas(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não conseguimos consultar as escalas "
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);
  };

  function onChangeItem(item){
    getAllEscalas();
  }

  return (
    <PageContainer heading="Escalas" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{marginBottom: 10}}>Gerenciar Escalas</div>
          <Divider />
          <div style={{marginTop: 24}}>
            <Card className={classes.root}>
              <CardContent>
                <CreateEscala dataDias={dataDias} onChangeItem={onChangeItem} />
                {isLoading !== true ? (
                  <TabelaEscalas
                    dataEscalas={dataEscalas}
                    dataDias={dataDias}
                    onChangeItem={onChangeItem}
                  />
                ) : (
                  <PageLoader />
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
